<template>
  <k-basic-modal-layout>
    <template v-slot:header>
      <div class="panel-header">
        <div class="d-flex align-items-center pr-20">
          <span class="title-border bg-blue-cyan mr-20"></span>
          <h1 class="hasab-title">Title</h1>
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="px-25">
        <p class="mb-10">
          Teszt body
        </p>
        <pre>{{ modal }}</pre>
      </div>
    </template>
    <template v-slot:footer>
      <div class="footer justify-space-between">
        <k-button
          variant="dark"
          label="Mégsem"
          @click="Close()"
          icon="fas fa-times"
          size="sm"
        ></k-button>
      </div>
    </template>
  </k-basic-modal-layout>
</template>

<script>
import { mapGetters } from 'vuex';
import { timeout } from '../../utils/common';

export default {
  name: 'teszt-modal',
  data() {
    return {};
  },
  async mounted() {
    this.$emit('set-loading', true);
    await timeout(1000);
    this.$emit('set-loading', false);
  },
  created() {},
  methods: {
    Close() {
      this.$emit('close');
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {},
  props: {
    modal: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
};
</script>
